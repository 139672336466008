
<template>
  <div class="sign">
    <div class="search">
      <i class="jym-saoma1"></i>
      <input type="text" v-model="signNum" placeholder="输入签到码" />
      <button @click="signCode('sign')">签到</button>
    </div>

    <div class="container">
      <ul>
        <li>
          <div class="text">
            <p>{{ info.bookname }}</p>
            <p>--{{ info.alias }}</p>
            <p class="small">
              共{{ info.make_num }}人，已签到{{ info.sign_num }}人，
              <span class="gold-num">未签到{{ info.make_num - info.sign_num }}人</span>
            </p>
          </div>
          <div class="btn">
            <button @click="signCode">
              <i class="jym-saoma"></i>扫码
            </button>
          </div>
        </li>
        <li v-for="(item, index) in list" :key="index">
          <div class="text" style="width: 100%">
            <p>
              {{ item.name }} {{ item.phone }}
              <span class="fr">{{ item.signtime }}</span>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { MessageBox } from 'mint-ui';
import { wechatEvevt } from "@libs/wechat";
import { signLog, sign_refund, sign_hotel } from "@api/user";
export default {
  data() {
    return {
      id: "",
      sid: "",
      signNum: "",
      is_hotel: "",
      info: {},
      list: {},
      flag: true
    };
  },
  created() {
    this.sid = this.$route.params.id;
    this.getList();
  },
  methods: {
    getList() {
      let that = this;

      signLog(that.sid)
        .then(res => {
          that.info = res.data.info;
          that.list = res.data.list;
        })
        .catch(() => {});
    },

    // 扫码
    signCode(status) {
      let that = this;
      let params = {
        id: that.id,
        sid: that.sid,
        signNum: that.signNum
      };

      if (status == "sign") {
        // 签到
        if (that.flag) {
          that.flag = false;
          that.$dialog.loading.open();
          sign_refund(params)
            .then(res => {
              that.signNum = "";
              that.$dialog.loading.close();
              that.flag = true;

              if(res.data.is_hotel == 1) {
                that.hotelDialog(res.data.id)
              } else {
                that.$dialog.success(res.msg);
              }
            })
            .catch(err => {
              that.$dialog.error(err.msg);
              that.$dialog.loading.close();
              that.flag = true;
            });
        }
      } else {
        // 扫码
        wechatEvevt("scanQRCode", {
          needResult: 1,
          scanType: ["qrCode"]
        })
          .then(res => {
            console.log(res);
            if (res.resultStr) {
              // params.id = res.resultStr.split('=')[1];
              sign_refund({
                id: res.resultStr.split("=")[1],
                sid: that.sid
              })
                .then(res => {
                  that.list.unshift(res.data);
                  if(res.data.is_hotel == 1) {                    
                    that.hotelDialog(res.data.id)
                  } else {
                    that.$dialog.success(res.msg);
                  }
                })
                .catch(err => {
                  that.$dialog.error(err.msg);
                });
            } else that.$dialog.error("没有扫描到什么！");
          })
          .catch(res => {
            console.log(res);
            if (res.is_ready) {
              res.wx.scanQRCode({
                needResult: 1,
                scanType: ["qrCode", "barCode"],
                success: function(res) {
                  if (res.resultStr) {
                    // params.id = res.resultStr.split('=')[1];
                    sign_refund({
                      id: res.resultStr.split("=")[1],
                      sid: that.sid
                    })
                      .then(res => {
                        that.list.unshift(res.data);
                        that.$dialog.success(res.msg);
                      })
                      .catch(err => {
                        that.$dialog.error(err.msg);
                      });
                  }
                },
                fail: function(res) {
                  console.log(res);
                  if (res.errMsg == "scanQRCode:permission denied") {
                    that.$dialog.error("没有权限");
                  }
                }
              });
            }
          });
      }
    },
    // 是否住店
    hotelDialog(id) {
      let that = this
      MessageBox({
        title: "提示",
        confirmButtonText: '是',
        cancelButtonText: '否',
        message: '签到成功！是否住校？',
        showCancelButton: true,
      }).then((status) => {
        if (status === "confirm") {
          that.$dialog.loading.open();
          sign_hotel({id: id, value: 1}).then(res => {
            that.$dialog.success(res.msg)
            setTimeout(() => {
              that.$dialog.loading.close();
              that.$router.go(0)
            }, 1500)
          }).catch((err) => {
            that.$dialog.error(err.msg);
            that.$dialog.loading.close();
          });
        } else {
          return;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.sign {
  .search {
    width: 100%;
    height: 0.8rem;
    background: white;
    position: relative;
    display: flex;
    justify-content: space-between;
    input {
      width: 5.7rem;
      margin: 0.1rem 0 0.1rem 0.3rem;
      height: 0.6rem;
      font-size: 0.24rem;
      border: 0.02rem solid #ddd;
      border-radius: 0.12rem;
      padding: 0 0.65rem;
    }
    i.jym-saoma1 {
      position: absolute;
      font-size: 0.36rem;
      top: 0.22rem;
      left: 0.5rem;
    }
    button {
      width: 0.9rem;
      height: 0.6rem;
      background: yellowgreen;
      margin: 0.1rem 0.3rem;
      font-size: 0.24rem;
      background: black;
      color: white;
      border-radius: 0.06rem;
    }
  }
  .container {
    margin-top: 0.2rem;
    ul li {
      margin-top: 0.1rem;
      width: 100%;
      background: white;
      font-size: 0.26rem;
      padding: 0.2rem 0.3rem;
      display: flex;
      justify-content: space-between;
      .text {
        width: 5.5rem;
        padding-right: 0.3rem;
        p {
          line-height: 0.4rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: 600;
        }
        p.small {
          font-weight: 500;
          font-size: 0.24rem;
        }
      }
      .btn {
        width: 1.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        button {
          color: white;
          width: 100%;
          height: 0.48rem;
          background: #26a2ff;
          text-align: center;
          line-height: 0.48rem;
          i {
            font-size: 0.28rem;
            margin-right: 0.1rem;
          }
        }
      }
    }
  }
}
</style>